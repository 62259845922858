import { useStyletron } from "baseui";
import { Button } from "components/button";
import { IngredientsLiveSearchInput, Input } from "components/input";
import { Select } from "components/select";
import { Ingredient, IngredientsGroup } from "containers/Recipes/recipes";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Controller, UseControllerProps } from "react-hook-form";
import { Menu, Plus, Trash } from "tabler-icons-react";
import { generateUUID } from "utils/misc";

import { UNITS } from "../../constants";

type RecipeIngredientsComposerProps = {
  value?: IngredientsGroup[];
  onChange?: (groups?: IngredientsGroup[]) => void;
  id?: string;
  error?: boolean;
  name: string;
  disabled?: boolean;
};

export default function RecipeIngredientsComposer({
  value,
  onChange,
  error,
  name,
  disabled,
}: RecipeIngredientsComposerProps): ReactElement {
  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const [groups, setGroups] = useState<IngredientsGroup[]>([]);
  const [changes, setChanges] = useState(0);
  const [css] = useStyletron();

  useEffect(() => {
    value?.map((group: IngredientsGroup) => {
      setGroups((groups) => [
        ...groups,
        {
          id: group.id,
          additionalInfo: String(group.id),
          position: group.position,
          name: group.name,
        },
      ]);
    });

    value?.map((group: IngredientsGroup) => {
      group?.ingredients?.map((ingredient: Ingredient) => {
        setIngredients((ingredients) => [
          ...ingredients,
          {
            ...(ingredient?.id && { id: ingredient?.id }),
            name: ingredient?.ingredient?.name,
            value: ingredient?.value,
            unit: ingredient?.unit,
            position: ingredient?.position,
            additionalInfo: String(ingredient?.id),
            groupAdditionalInfo: String(group.id),
          },
        ]);
      });
    });
  }, []);

  useEffect(() => {
    onChange &&
      onChange(
        groups
          ?.filter((item) => item.name)
          ?.map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (group: any, index: number) =>
              group.name && {
                name: group.name,
                position: index + 1,
                additionalInfo: group.additionalInfo,
                ingredients: ingredients
                  .filter(
                    (ingredient: Ingredient) =>
                      ingredient.groupAdditionalInfo === group.additionalInfo &&
                      !!ingredient.name &&
                      !!ingredient.unit &&
                      !!ingredient.value
                  )
                  .map((ingredient: Ingredient, index: number) => ({
                    name: ingredient.name,
                    value: ingredient.value,
                    unit: ingredient.unit,
                    position: index + 1,
                    additionalInfo: ingredient.additionalInfo,
                  })),
              }
          )
      );
  }, [changes, ingredients]);

  const handleReorderGroups = (
    items: IngredientsGroup[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleReorderIngredients = (
    items: Ingredient[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(items);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleOnDragEnd = ({ source, destination, type }: DropResult) => {
    if (!destination) return;
    if (type === "groups") {
      const newItems = handleReorderGroups(
        groups,
        source.index,
        destination.index
      );
      setGroups(newItems);
    } else {
      const newItems = handleReorderIngredients(
        ingredients,
        source.index,
        destination.index
      );
      setIngredients(newItems);
    }
  };

  return (
    <div id={"ingredientGroups"}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="groups" type="groups">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} id={name}>
              {groups.map((group: IngredientsGroup, index: number) => (
                <Draggable
                  key={`step-${group.additionalInfo}`}
                  draggableId={group.additionalInfo}
                  index={index}
                >
                  {(provided) => (
                    <div {...provided.draggableProps} ref={provided.innerRef}>
                      <div
                        className={css({
                          display: "flex",
                          gap: "8px",
                          marginTop: "16px",
                        })}
                      >
                        <Button
                          kind="secondary"
                          type="button"
                          size="mini"
                          disabled={disabled}
                          $style={{ alignSelf: "start" }}
                          {...provided.dragHandleProps}
                        >
                          <Menu size={16} />
                        </Button>

                        <Input
                          name="group"
                          placeholder="Nazwa grupy"
                          size="mini"
                          value={group.name}
                          error={error}
                          disabled={disabled}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            setGroups((groups) => {
                              const editedGroupIndex = groups?.findIndex(
                                (item) =>
                                  item.additionalInfo === group.additionalInfo
                              );
                              groups[editedGroupIndex].name =
                                event?.target?.value;
                              return groups;
                            });
                            setChanges((changes) => changes + 1);
                          }}
                        />

                        <Button
                          onClick={() => {
                            setGroups(
                              groups?.filter(
                                (item) =>
                                  item?.additionalInfo !== group?.additionalInfo
                              )
                            );
                            setIngredients(
                              ingredients?.filter(
                                (item) =>
                                  item?.additionalInfo !== group?.additionalInfo
                              )
                            );
                            setChanges((changes) => changes + 1);
                          }}
                          kind="secondary"
                          type="button"
                          size="mini"
                          disabled={disabled}
                          $style={{ alignSelf: "start" }}
                        >
                          <Trash size={16} />
                        </Button>
                      </div>

                      <Droppable
                        droppableId={`ingredients-of-group-${group.additionalInfo}`}
                        type={`ingredients-of-group-${group.additionalInfo}`}
                      >
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            className={css({ marginTop: "8px" })}
                          >
                            {!!ingredients?.length &&
                              ingredients
                                .filter(
                                  (ingredient: Ingredient) =>
                                    ingredient.groupAdditionalInfo ===
                                    group.additionalInfo
                                )
                                .map(
                                  (ingredient: Ingredient, index: number) => (
                                    <Draggable
                                      key={`step-${ingredient.additionalInfo}`}
                                      draggableId={ingredient.additionalInfo}
                                      index={index}
                                    >
                                      {(provided) => (
                                        <div
                                          {...provided.draggableProps}
                                          ref={provided.innerRef}
                                          className={css({
                                            display: "flex",
                                            gap: "8px",
                                            marginTop: "8px",
                                          })}
                                        >
                                          <Button
                                            kind="secondary"
                                            type="button"
                                            size="mini"
                                            disabled={disabled}
                                            $style={{ alignSelf: "start" }}
                                            {...provided.dragHandleProps}
                                          >
                                            <Menu size={16} />
                                          </Button>

                                          <div
                                            className={css({
                                              display: "flex",
                                              gap: "8px",
                                              alignItems: "center",
                                              flexGrow: 1,
                                            })}
                                          >
                                            <div
                                              className={css({
                                                flexBasis: "25%",
                                              })}
                                            >
                                              <Input
                                                name="count"
                                                type="number"
                                                step={0.01}
                                                min={0}
                                                placeholder="Ilość"
                                                disabled={disabled}
                                                value={ingredient.value}
                                                error={error}
                                                size="mini"
                                                onChange={(
                                                  event: ChangeEvent<HTMLInputElement>
                                                ) => {
                                                  setIngredients(
                                                    (ingredients) => {
                                                      const editedItemIndex = ingredients?.findIndex(
                                                        (item) =>
                                                          item.additionalInfo ===
                                                          ingredient.additionalInfo
                                                      );
                                                      ingredients[
                                                        editedItemIndex
                                                      ].value =
                                                        !!parseFloat(
                                                          event.target.value
                                                        ) &&
                                                        Math.abs(
                                                          parseFloat(
                                                            event.target.value
                                                          )
                                                        ) >= 0
                                                          ? Math.abs(
                                                              parseFloat(
                                                                event.target
                                                                  .value
                                                              )
                                                            )
                                                          : undefined;
                                                      setChanges(
                                                        (changes) => changes + 1
                                                      );

                                                      return ingredients;
                                                    }
                                                  );
                                                }}
                                              />
                                            </div>

                                            <div
                                              className={css({
                                                flexBasis: "25%",
                                              })}
                                            >
                                              <Select
                                                {...(ingredient?.unit && {
                                                  value: [
                                                    { label: ingredient.unit },
                                                  ],
                                                })}
                                                placeholder="Jedn."
                                                options={UNITS}
                                                error={error}
                                                disabled={disabled}
                                                size="mini"
                                                onChange={(params) => {
                                                  setIngredients(
                                                    (ingredients) => {
                                                      const editedItemIndex = ingredients?.findIndex(
                                                        (item) =>
                                                          item.additionalInfo ===
                                                          ingredient.additionalInfo
                                                      );
                                                      ingredients[
                                                        editedItemIndex
                                                      ].unit = params?.option
                                                        ?.id as string;
                                                      setChanges(
                                                        (changes) => changes + 1
                                                      );

                                                      return ingredients;
                                                    }
                                                  );
                                                }}
                                              />
                                            </div>

                                            <div
                                              className={css({
                                                flexBasis: "50%",
                                              })}
                                            >
                                              <IngredientsLiveSearchInput
                                                value={ingredient.name}
                                                error={error}
                                                currentIngredient={ingredient}
                                                setIngredients={setIngredients}
                                                name="ingredient"
                                                disabled={disabled}
                                              />
                                            </div>
                                          </div>

                                          <Button
                                            onClick={() => {
                                              setIngredients(
                                                ingredients?.filter(
                                                  (item) =>
                                                    item?.additionalInfo !==
                                                    ingredient?.additionalInfo
                                                )
                                              );
                                              setChanges(
                                                (changes) => changes + 1
                                              );
                                            }}
                                            kind="secondary"
                                            type="button"
                                            size="mini"
                                            disabled={disabled}
                                          >
                                            <Trash size={16} />
                                          </Button>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>

                      <div
                        className={css({
                          display: "flex",
                          justifyContent: "flex-end",
                        })}
                      >
                        <Button
                          type="button"
                          kind="secondary"
                          disabled={disabled}
                          onClick={() => {
                            const newAdditionalInfo = generateUUID();

                            setIngredients([
                              ...ingredients,
                              {
                                additionalInfo:
                                  newAdditionalInfo || String(Date.now()),
                                groupAdditionalInfo: group.additionalInfo,
                              },
                            ]);
                          }}
                          $style={{
                            display: "flex",
                            gap: "8px",
                            alignItems: "center",
                            marginTop: "8px",
                            marginRight: "40px",
                          }}
                          size="mini"
                        >
                          <Plus size={14} /> Dodaj składnik
                        </Button>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <div
        className={css({
          display: "flex",
          justifyContent: "flex-end",
        })}
      >
        <Button
          type="button"
          kind="secondary"
          disabled={disabled}
          onClick={() => {
            const newAdditionalInfo = generateUUID();

            setGroups((groups) => [
              ...groups,
              {
                additionalInfo: newAdditionalInfo || String(Date.now()),
                position: groups?.length + 1,
              },
            ]);
          }}
          $style={{
            display: "flex",
            gap: "8px",
            alignItems: "center",
            marginTop: "16px",
            marginRight: "40px",
          }}
          size="mini"
        >
          <Plus size={14} /> Dodaj grupę składników
        </Button>
      </div>
    </div>
  );
}

export function ControlledRecipeIngredientsComposer({
  control,
  name,
  rules,
  error,
  ...rest
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
UseControllerProps<any> & RecipeIngredientsComposerProps): React.ReactElement {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value } }) => {
        return (
          <RecipeIngredientsComposer
            onChange={onChange}
            value={value}
            name={name}
            error={error}
            {...rest}
          />
        );
      }}
    />
  );
}
