import ActivityLogsIndex from "containers/ActivityLogs/Index";
import ActivityLogsShow from "containers/ActivityLogs/Show";
import AllergensCreate from "containers/Allergens/Create";
import AllergensIndex from "containers/Allergens/Index";
import AllergensShow from "containers/Allergens/Show";
import AllergensUpdate from "containers/Allergens/Update";
import ArticlesCreate from "containers/Articles/Create";
import ArticlesIndex from "containers/Articles/Index";
import ArticlesShow from "containers/Articles/Show";
import ArticlesUpdate from "containers/Articles/Update";
import Invitation from "containers/Auth/Invitation";
import AvatarsCreate from "containers/Avatars/Create";
import AvatarsIndex from "containers/Avatars/Index";
import AvatarsShow from "containers/Avatars/Show";
import BrandsCreate from "containers/Brands/Create";
import BrandsIndex from "containers/Brands/Index";
import BrandsShow from "containers/Brands/Show";
import BrandsUpdate from "containers/Brands/Update";
import CategoriesCreate from "containers/Categories/Create";
import CategoriesIndex from "containers/Categories/Index";
import CategoriesShow from "containers/Categories/Show";
import CategoriesUpdate from "containers/Categories/Update";
import ImagesCreate from "containers/Images/Create";
import ImagesIndex from "containers/Images/Index";
import ImagesShow from "containers/Images/Show";
import ImagesUpdate from "containers/Images/Update";
import ProductsCreate from "containers/Products/Create";
import ProductsIndex from "containers/Products/Index";
import ProductsShow from "containers/Products/Show";
import ProductsUpdate from "containers/Products/Update";
import RecipesIndex from "containers/Recipes/Index";
import RecipesShow from "containers/Recipes/Show";
import RecipesUpdate from "containers/Recipes/Update";
import RolesCreate from "containers/Roles/Create";
import RolesIndex from "containers/Roles/Index";
import RolesShow from "containers/Roles/Show";
import RolesUpdate from "containers/Roles/Update";
import SeoTagsetsIndex from "containers/SeoTagsets/Index";
import SeoTagsetsShow from "containers/SeoTagsets/Show";
import SeoTagsetsUpdate from "containers/SeoTagsets/Update";
import SlidesCreate from "containers/Slides/Create";
import SlidesIndex from "containers/Slides/Index";
import SlidesShow from "containers/Slides/Show";
import SlidesUpdate from "containers/Slides/Update";
import StaticPagesCreate from "containers/StaticPages/Create";
import StaticPagesIndex from "containers/StaticPages/Index";
import StaticPagesShow from "containers/StaticPages/Show";
import StaticPagesUpdate from "containers/StaticPages/Update";
import TagsCreate from "containers/Tags/Create";
import TagsIndex from "containers/Tags/Index";
import TagsShow from "containers/Tags/Show";
import TagsUpdate from "containers/Tags/Update";
import { ReactElement } from "react";

import Login from "./containers/Auth/Login";
import Logout from "./containers/Auth/Logout";
import ResetPassword from "./containers/Auth/ResetPassword";
import DictionariesIndex from "./containers/Dictionaries/Index";
import DictionariesShow from "./containers/Dictionaries/Show";
import Error404 from "./containers/ErrorPages/404";
import Home from "./containers/Home";
import MyAccount from "./containers/MyAccount";
import UsersCreate from "./containers/Users/Create";
import UsersIndex from "./containers/Users/Index";
import UsersShow from "./containers/Users/Show";
import UsersUpdate from "./containers/Users/Update";

export type Route = {
  path: string;
  component: () => ReactElement;
  exact?: boolean;
};

export type ModelRoute = {
  name: string;
  path: string;
  index?: Route;
  show?: Route;
  update?: Route;
  create?: Route;
};

const ROUTES = {
  private: {
    basic: [
      {
        path: "/",
        component: Home,
        exact: true,
      },
      {
        path: "/my-account",
        component: MyAccount,
        exact: true,
      },
      {
        path: "/logout",
        component: Logout,
        exact: true,
      },
    ] as Route[],
    models: [
      {
        name: "activityLogs",
        path: "/activity-logs",
        index: {
          path: "/",
          component: ActivityLogsIndex,
          exact: true,
        },
        show: {
          path: "/:id",
          component: ActivityLogsShow,
          exact: true,
        },
      },
      {
        name: "allergens",
        path: "/allergens",
        index: {
          path: "/",
          component: AllergensIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: AllergensCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: AllergensShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: AllergensUpdate,
          exact: true,
        },
      },
      {
        name: "articles",
        path: "/articles",
        index: {
          path: "/",
          component: ArticlesIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: ArticlesCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: ArticlesShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: ArticlesUpdate,
          exact: true,
        },
      },
      {
        name: "avatars",
        path: "/avatars",
        index: {
          path: "/",
          component: AvatarsIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: AvatarsCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: AvatarsShow,
          exact: true,
        },
      },
      {
        name: "brands",
        path: "/brands",
        index: {
          path: "/",
          component: BrandsIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: BrandsCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: BrandsShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: BrandsUpdate,
          exact: true,
        },
      },
      {
        name: "categories",
        path: "/categories",
        index: {
          path: "/",
          component: CategoriesIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: CategoriesCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: CategoriesShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: CategoriesUpdate,
          exact: true,
        },
      },
      {
        name: "slides",
        path: "/slides",
        index: {
          path: "/",
          component: SlidesIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: SlidesCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: SlidesShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: SlidesUpdate,
          exact: true,
        },
      },
      {
        name: "dictionaries",
        path: "/dictionaries",
        index: {
          path: "/",
          component: DictionariesIndex,
          exact: true,
        },
        show: {
          path: "/:id",
          component: DictionariesShow,
          exact: true,
        },
      },
      {
        name: "products",
        path: "/products",
        index: {
          path: "/",
          component: ProductsIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: ProductsCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: ProductsShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: ProductsUpdate,
          exact: true,
        },
      },
      {
        name: "recipes",
        path: "/recipes",
        index: {
          path: "/",
          component: RecipesIndex,
          exact: true,
        },
        show: {
          path: "/:id",
          component: RecipesShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: RecipesUpdate,
          exact: true,
        },
      },
      {
        name: "roles",
        path: "/roles",
        index: {
          path: "/",
          component: RolesIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: RolesCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: RolesShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: RolesUpdate,
          exact: true,
        },
      },
      {
        name: "seoTagsets",
        path: "/seo-tagsets",
        index: {
          path: "/",
          component: SeoTagsetsIndex,
          exact: true,
        },
        show: {
          path: "/:id",
          component: SeoTagsetsShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: SeoTagsetsUpdate,
          exact: true,
        },
      },
      {
        name: "static-pages",
        path: "/static-pages",
        index: {
          path: "/",
          component: StaticPagesIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: StaticPagesCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: StaticPagesShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: StaticPagesUpdate,
          exact: true,
        },
      },
      {
        name: "tags",
        path: "/tags",
        index: {
          path: "/",
          component: TagsIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: TagsCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: TagsShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: TagsUpdate,
          exact: true,
        },
      },
      {
        name: "users",
        path: "/users",
        index: {
          path: "/",
          component: UsersIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: UsersCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: UsersShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: UsersUpdate,
          exact: true,
        },
      },
      {
        name: "images",
        path: "/images",
        index: {
          path: "/",
          component: ImagesIndex,
          exact: true,
        },
        create: {
          path: "/create",
          component: ImagesCreate,
          exact: true,
        },
        show: {
          path: "/:id",
          component: ImagesShow,
          exact: true,
        },
        update: {
          path: "/:id/update",
          component: ImagesUpdate,
          exact: true,
        },
      },
    ] as ModelRoute[],
  },
  auth: [
    {
      path: "/login",
      component: Login,
      exact: true,
    },
    {
      path: "/reset-password/:token",
      component: ResetPassword,
      exact: true,
    },
    {
      path: "/invitation/:token",
      component: Invitation,
      exact: true,
    },
  ] as Route[],
  shared: [] as Route[],
  fallback: [
    {
      path: "*",
      component: Error404,
      exact: false,
    },
  ] as Route[],
};

export default ROUTES;
