import { useLazyQuery } from "@apollo/client";
import { useStyletron } from "baseui";
import { Ingredient } from "containers/Recipes/recipes";
import { INGREDIENTS_LIVE_SEARCH } from "containers/Recipes/recipes.gql";
import React, { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { StyleObject } from "styletron-react";

import Input from "./input";

type IngredientsLiveSearchInputProps = {
  value?: string;
  error?: boolean;
  currentIngredient: Ingredient;
  setIngredients: any;
  name: string;
  disabled?: boolean;
  $style?: StyleObject;
};

export function IngredientsLiveSearchInput({
  value,
  currentIngredient,
  setIngredients,
  error,
  name,
  disabled,
  $style,
}: IngredientsLiveSearchInputProps): ReactElement {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isOptionChoosed, setIsOptionChoosed] = useState(false);
  const [fetchData, { data }] = useLazyQuery(INGREDIENTS_LIVE_SEARCH);
  const [changes, setChanges] = useState(0);
  const [css, theme] = useStyletron();

  useEffect(() => {
    if (value && value?.length >= 3) {
      fetchData({
        variables: {
          filter: {
            name: {
              like: `${value}%`,
            },
          },
          offset: 0,
          limit: 24,
          sorting: {
            field: "name",
            direction: "ASC",
          },
        },
      });
    }

    setChanges((changes) => changes + 1);
  }, [value]);

  useEffect(() => {
    if (
      value &&
      value?.length >= 3 &&
      !!data?.ingredients?.nodes?.length &&
      !isOptionChoosed
    )
      setIsPopoverOpen(true);
    else {
      setIsPopoverOpen(false);
      setIsOptionChoosed(false);
    }
  }, [value]);

  typeof window !== "undefined" &&
    window?.addEventListener("click", () => {
      if (!isMouseOver) setIsPopoverOpen(false);
    });

  return (
    <div className={css({ position: "relative", ...$style })}>
      <Input
        name={name}
        value={value}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setIngredients((ingredients: Ingredient[]) => [
            ...ingredients.map((item) => {
              return item.additionalInfo === currentIngredient.additionalInfo
                ? { ...item, name: event.target.value }
                : item;
            }),
          ]);

          setChanges((changes) => changes + 1);
        }}
        size="mini"
        autoComplete="off"
        placeholder="Nazwa"
        error={error}
        disabled={disabled}
      />

      {!!data?.ingredients?.nodes?.length &&
        value &&
        value?.length >= 3 &&
        isPopoverOpen && (
          <div
            onMouseEnter={() => setIsMouseOver(true)}
            onMouseLeave={() => setIsMouseOver(false)}
            className={css({
              position: "absolute",
              top: "36px",
              zIndex: 50,
              maxHeight: "144px",
              width: "100%",
              overflow: "auto",
              boxShadow: "unset",
              borderRadius: theme.borders.radius200,
              border: `2px solid ${theme.colors.borderSelected}`,
              backgroundColor: theme.colors.inputFill,
              padding: `${theme.sizing.scale200} 0`,
            })}
          >
            {data?.ingredients?.nodes?.map((ingredient: Ingredient) => (
              <div
                key={`ingredients-live-search-item-${ingredient?.id}`}
                className={css({
                  cursor: "pointer",
                  fontSize: "12px",
                  padding: `${theme.sizing.scale400}`,
                  ":hover": {
                    background: theme.colors.inputFillActive,
                  },
                })}
                onClick={() => {
                  setIngredients((ingredients: Ingredient[]) => [
                    ...ingredients.map((item) => {
                      return item.additionalInfo ===
                        currentIngredient.additionalInfo
                        ? { ...item, name: ingredient.name }
                        : item;
                    }),
                  ]);

                  setIsPopoverOpen(false);
                  setIsOptionChoosed(true);

                  setChanges((changes) => changes + 1);
                }}
              >
                {ingredient?.name}
              </div>
            ))}
          </div>
        )}
    </div>
  );
}
