import { gql } from "@apollo/client";

export const RECIPES_INDEX = gql`
  query(
    $pageSize: Int
    $offset: Int
    $filter: RecipeFilter
    $sorting: [RecipeSort!]
  ) {
    recipes(
      paging: { limit: $pageSize, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
        createdAt
        createdBy {
          id
          firstName
          lastName
          username
          email
        }
        updatedAt
        updatedBy {
          id
          firstName
          lastName
          username
          email
        }
        isVisible
        slug
        author {
          name
          user {
            id
            firstName
            lastName
            username
            email
          }
          brand {
            id
            name
          }
        }
      }
    }
  }
`;

export const RECIPES_LIVE_SEARCH = gql`
  query($offset: Int, $filter: RecipeFilter, $sorting: [RecipeSort!]) {
    recipes(
      paging: { limit: 5, offset: $offset }
      sorting: $sorting
      filter: $filter
    ) {
      nodes {
        id
        slug
        name
        imageUrl
      }
    }
  }
`;

export const RECIPES_SHOW = gql`
  query RecipeShow($id: Int!) {
    recipe(id: $id) {
      id
      name
      slug
      content
      createdAt
      createdBy {
        id
        firstName
        lastName
        username
        email
      }
      updatedAt
      updatedBy {
        id
        firstName
        lastName
        username
        email
      }
      isVisible
      categories {
        id
        name
      }
      tags {
        id
        name
      }
      allergens {
        id
        name
      }
      author {
        user {
          firstName
          lastName
          id
          username
          email
        }
        brand {
          id
          name
        }
        name
      }
      recipeSteps {
        id
        additionalInfo
        content
        position
      }
      ingredientGroups {
        id
        name
        position
        additionalInfo
        ingredients {
          id
          additionalInfo
          value
          unit
          position
          ingredient {
            name
          }
        }
      }
      imageUrl
      videoUrl
      difficulty
      preparationTime
      servingCount
      calorieAmount
      proteinAmount
      carbohydrateAmount
      fatAmount
      seoTagset {
        id
        metaTitle
        metaDescription
        metaKeywords
        ogTitle
        ogDescription
        canonical
        robots
      }
    }
  }
`;

export const RECIPES_CREATE = gql`
  mutation RecipeCreate(
    $recipeCreateInput: RecipeCreateInput!
    $recipeAuthorAssignInput: RecipeAuthorAssignInput!
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    recipeCreate(
      recipeCreateInput: $recipeCreateInput
      recipeAuthorAssignInput: $recipeAuthorAssignInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const RECIPES_UPDATE = gql`
  mutation RecipeUpdate(
    $recipeUpdateInput: RecipeUpdateInput!
    $recipeAuthorAssignInput: RecipeAuthorAssignInput
    $seoTagsetUpsertInput: SeoTagsetUpsertInput
  ) {
    recipeUpdate(
      recipeUpdateInput: $recipeUpdateInput
      recipeAuthorAssignInput: $recipeAuthorAssignInput
      seoTagsetUpsertInput: $seoTagsetUpsertInput
    ) {
      id
    }
  }
`;

export const RECIPES_DELETE = gql`
  mutation RecipeDelete($recipeDeleteInput: RecipeDeleteInput!) {
    recipeDelete(recipeDeleteInput: $recipeDeleteInput) {
      id
    }
  }
`;

export const INGREDIENTS_LIVE_SEARCH = gql`
  query(
    $sorting: [IngredientSort!]
    $filter: IngredientFilter!
    $limit: Int
    $offset: Int
  ) {
    ingredients(
      paging: { offset: $offset, limit: $limit }
      sorting: $sorting
      filter: $filter
    ) {
      totalCount
      nodes {
        id
        name
      }
    }
  }
`;
